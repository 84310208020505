@use "@angular/material" as mat;
$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Satoshi",
);
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;800;900&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@1,900,700,500,301,701,300,501,401,901,400,2&display=swap");
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
@import "./assets/styles/buttons.scss";
@import "./assets/styles/font-style.scss";
@import "stream-chat-angular/src/assets/styles/v2/scss/index.scss";

// Movergy Component vars
:root {
  --dot-diameter: 350px;
  --circle-border-width: 3px;
  --default-color: gainsboro;
}

@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  overflow: auto !important;
  min-width: 100% !important;
}

body {
  font-weight: 600 !important;
}

/* Stile del tooltip */
.tooltip .tooltip-inner {
  background-color: #ffffff; /* Sfondo bianco */
  color: #2f2f2f; /* Testo grigio scuro (#2F2F2F) */
  opacity: 1;
  border-radius: 10px !important;
}

.tooltip .tooltip-arrow {
  border-color: #ffffff !important; /* Sfondo bianco della freccia */
}

.tooltip .tooltip-arrow::before,
.tooltip .tooltip-arrow::after {
  border-top-color: #ffffff !important; /* Sfondo bianco dell'angolo */
}

.custom-tooltip {
  --bs-tooltip-bg: white !important;
  --bs-tooltip-max-width: 300px !important;
  --bs-tooltip-padding-x: 10px !important;
  --bs-tooltip-padding-y: 10px !important;
  border-radius: 10px !important;
  opacity: 1 !important;
}

.tooltip {
  box-shadow: -3px -1px 6px #00000026;
}
/* Stile tooltip */

/* Angular Material Component */
.mat-drawer-container,
.mat-drawer-inner-container {
  background-color: white !important;
}
.mat-drawer-container {
  height: 100%;
}
.mat-expansion-panel-body {
  padding: 1rem !important;
}
.mat-form-field-infix {
  border-bottom: 1px solid !important;
}
.mat-select-panel {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  background-color: white;
}
.mat-dialog-actions {
  background-color: white;
}
.mat-button .mat-button-wrapper > #mat-calendar-button-0 {
  color: #f7257d;
  font-weight: bold;
}
.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87) !important;
  border-color: transparent !important;
}
.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-selected {
  background-color: #2f2f2f;
  color: #fff;
}
/* Angular Material Component */

// .text-center {
//   text-align: center;
// }
// .circle {
//   border-radius: 50%;
// }
// .icon-display {
//   transform: scale(2);
// }
.rounded {
  border-radius: 5px;
}
.overflow-auto {
  overflow-y: auto;
}
// .h-0 {
//   height: 0;
// }
// .mh-100 {
//   min-height: 100%;
// }
.w-100 {
  width: 100%;
}
// .mb-10 {
//   margin-bottom: 10px;
// }
// .text_lightblue {
//   color: #009ddd;
// }
.lightBlue {
  color: white;
  background-color: #f7257d;
}
.colored {
  background-color: #2f2f2f;
  color: white;
}
.text-blue {
  color: #2f2f2f;
}
.bg-grey {
  background-color: #dddddd;
}
.activeLink {
  color: #2f2f2f !important;
  font-weight: 900 !important;
}
.text-gradient-drfeel {
  display: inline-block;
  white-space: nowrap;
  background: #6c00ff;
  background: linear-gradient(to right, #ff0058 0%, #6c00ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Style Calendar in Agenda */
.calendar-sidebar {
  background-color: #2f2f2f !important;
  box-shadow: 5px 0 18px -3px #2f2f2f !important;
}
.calendar-sidebar > .month-list > .calendar-months > li.active-month,
#eventListToggler,
.calendar-sidebar > span#sidebarToggler,
.calendar-sidebar > .month-list > .calendar-months > li:hover {
  background-color: #0668d1 !important;
}
th[colspan="7"] {
  color: #0f3564 !important;
}
th[colspan="7"]::after {
  background-color: rgba(23, 45, 81, 0.15) !important;
}
.event-list > .event-empty > p {
  color: #0f3564 !important;
}
.event-list > .event-empty {
  background-color: rgba(23, 45, 81, 0.15) !important;
  border: 1px solid #0f3564 !important;
}
.calendar-inner::after {
  background-color: rgba(23, 45, 81, 0.15) !important;
}
.calendar-sidebar > span#sidebarToggler,
.evo-calendar {
  box-shadow: 5px 0 18px -3px #2f2f2f !important;
}
#eventListToggler {
  box-shadow: 5px 0 18px -3px #2f2f2f !important;
}
.calendar-sidebar > .calendar-year,
.calendar-sidebar > .month-list {
  background-color: #2f2f2f !important;
}
@media screen and (max-width: 768px) {
  .calendar-events {
    box-shadow: -5px 0 18px -3px rgb(23 45 81 / 50%) !important;
  }
}
/* Style Calendar in Agenda */

// Pia images circle
.img-profile-container {
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  border: 1px solid #2f2f2f;
  &.activeDoc {
    border: 3px solid #f7257d;
    padding: 1px;
  }
  img.img-profile {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
  }
}
.bolder-border {
  border: 5px solid #2f2f2f;
}
.img-profile-container {
  &.small {
    width: 40px;
    height: 40px;
  }
  &.medium {
    width: 50px;
    height: 50px;
  }
  &.large {
    width: 100px;
    height: 100px;
  }
  &.big-border {
    border: 3px solid #2f2f2f;
  }
  &.width82 {
    width: 82px;
    height: 82px;
  }
  &.width90 {
    width: 90px;
    height: 90px;
  }
}

// Shared components
app-user-info {
  display: flex;
  place-content: center flex-end;
  flex-direction: row;
  flex: 1 1 0;
  align-items: center;
}

mat-form-field {
  &.do3-input {
    width: 100%;
  }
  input {
    border: none;
    width: 100%;
  }
  input:focus,
  input:active,
  input:focus-within {
    outline: none;
    border: none;
  }
}

.right-border {
  border-right: 1px solid #2f2f2f;
}
.left-border {
  border-left: 1px solid #2f2f2f;
  @media screen and (max-width: 767px) {
    border-left: none;
  }
}

.container.main-container {
  min-width: 94%;
}

.margin140 {
  margin-top: 140px;
  margin-bottom: 140px;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

/* Bootstrap */
.modal-backdrop {
  z-index: 0 !important;
}
.drfeel-color {
  color: #f7257d;
}
.drfeel-bg {
  background-color: #f7257d;
  color: #fff;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .mat-drawer-content {
    overflow: hidden scroll !important;
  }
  .cronologia,
  .fatturazione {
    .scroll-list,
    .scroll-div {
      width: calc(100% - 100px);
    }
  }
  app-user-info {
    place-content: center center;
  }
  .box-movergy {
    width: 100% !important;
    height: auto !important;
    .movergy-text {
      font-size: 12px !important;
      .value {
        font-size: 42px !important;
      }
    }
  }
}
